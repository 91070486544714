angular.module("vdsr", ['ngCookies','ui.router'])
   .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider',
   function($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) {

      $locationProvider.html5Mode(true);

      // For any unmatched url, send to /route1
      $urlRouterProvider.otherwise('/login');

      $stateProvider
			.state('login', {
				url: '/login',
				templateUrl: "/partials/visitor/login/login.partial.html"
			})
			.state('lostpw', {
				url: '/lostpw',
				templateUrl: "/partials/visitor/lostpw/lostpw.partial.html"
			});
			
	   // Purpose of this interceptor: 
		// Imagine that we get a csrf token when a user visits the page for the first time 
		// and imagine that user went to bathroom without logging in. He came back 
		//  way later when his session timed out on server. If he tried to log in 
		//  with his old csrf token, that would be invalid. What this is doing is that 
		//  before he makes a non-GET request to server, it fetches a new csrf token 
		//   and adds that to the header of the request that will go to server 
		//   thus circumventing the issue of timeouts
	   $httpProvider.interceptors.push(['$q', function($q){
			return {
				'request': function(config){
					
					if (config.method === 'GET') {
						return config;
					}
					
					var deferred = $q.defer();
					
					 jQuery.get('/csrfToken?'+(new Date()).getTime(), function(res){
						//console.log('token', res);
						config.headers['X-CSRF-Token'] = res._csrf;
						
						deferred.resolve(config);
					});
					return deferred.promise;
				}
			};
		}])
	   
		
}])
